import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDropbox, faGoogle, faWindows, faApple, faSquarespace } from '@fortawesome/free-brands-svg-icons';
import { faBoxOpen, faBuilding, faKey, faReceipt } from '@fortawesome/free-solid-svg-icons';

function Checklist({ onSelect }) {
  const [checkedItems, setCheckedItems] = useState({});

  const iconMapping = {
    "DropBox": faDropbox,
    "Box": faBoxOpen,
    "Bitwarden": faKey,
    "QuickBooks": faReceipt,
    "Google Workspace": faGoogle,
    "Microsoft Entra": faBuilding,
    "Windows": faWindows,
    "MacOS": faApple,
    "SquareSpace": faSquarespace
  };
  
  const technologies = [
    "DropBox",
    "Box",
    "Bitwarden",
    "QuickBooks",
    "Google Workspace",
    "Microsoft Entra",
    "Windows",
    "MacOS",
    "SquareSpace"
  ];

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
      
    setCheckedItems(prevCheckedItems => {
      // Create the new state object
      const updatedCheckedItems = {
        ...prevCheckedItems,
        [name]: checked
      };
  
      // Optionally call onSelect with all selected items using the updated state
      onSelect(Object.keys(updatedCheckedItems).filter(item => updatedCheckedItems[item]));
  
      // Return the new state
      return updatedCheckedItems;
    });
  };
  
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <div>
        <form style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '10px' }}>
          {technologies.map((tech, index) => (
            <label key={index} style={{ display: "flex", alignItems: "center", marginBottom: "5px" }}>
              <input
                type="checkbox"
                name={tech}
                checked={checkedItems[tech] || false}
                onChange={handleCheckboxChange}
              />
              <FontAwesomeIcon icon={iconMapping[tech]} style={{ marginRight: "10px" }} />
              {tech}
            </label>
          ))}
        </form>
      </div>
    </div>
  );
  
}

export default Checklist;
