import React, { useState } from 'react';

const HardeningModal = ({ tech, onClose, steps }) => {
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  
  const goToNextStep = () => {
    if(currentStepIndex < steps.length - 1) {
      setCurrentStepIndex(currentStepIndex + 1);
    }
  };
  const goToPrevStep = () => {
    if(currentStepIndex > 0) {
      setCurrentStepIndex(currentStepIndex - 1);
    }
  };

  const currentStep = steps[currentStepIndex];

  return (
    <div className="modal-backdrop">
      <div className="modal-content">
        <h3>Hardening Steps for {tech} ({currentStepIndex+1}/{steps.length})</h3>
        <h4>{currentStep.title}</h4>
        {/* Render each line in the text array */}
        {currentStep.text?.map((line, index) => (
          <p key={index}>{line}</p>
        ))}
        {currentStep.image && <img src={currentStep.image} alt={`Step ${currentStepIndex + 1}`} />}
        <div>
          {currentStepIndex < steps.length - 1 && (
            <button onClick={goToNextStep}>Next</button>
          )}
          {currentStepIndex > 0  && (
            <button onClick={goToPrevStep}>Back</button>
          )}
          <button onClick={onClose}>Close</button>
        </div>
      </div>
    </div>
  );
};

export default HardeningModal;
