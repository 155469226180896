import React, { useState } from 'react';
import './App.css';
import Checklist from './Checklist';
import GuideTable from './GuideTable';
import HardeningModal from './HardeningModal';
import hardeningSteps from './hardeningSteps.json';


function App() {
  const [selectedTechnologies, setSelectedTechnologies] = useState([]);
  const [activeTech, setActiveTech] = useState(null); // State to track the active technology for hardening

  
    const openModal = (tech) => {
      setActiveTech(tech);
    };
  
    const closeModal = () => {
      setActiveTech(null);
    };
    return (
      <div className="app-container">
          <header className="App-header">
        <a href="https://adversis.io" target="_blank" rel="noopener noreferrer">
    <img src={process.env.PUBLIC_URL + '/logo.png'} alt="Logo" />
</a>


        <Checklist onSelect={setSelectedTechnologies} />
        <GuideTable 
          technologies={selectedTechnologies} 
          onBeginHardening={openModal} 
          hardeningSteps={hardeningSteps}/>
        {activeTech && 
          <HardeningModal 
            tech={activeTech} 
            onClose={closeModal} 
            steps={hardeningSteps[activeTech] || []}
            //hardeningSteps={hardeningSteps} 
          />
        }
      
      
</header>
      </div>
      
    );
  }




export default App;
